import React from 'react';
import styled from 'styled-components';
import P from '../_common/P';
import H4 from '../_common/H4';
import PageDescription from '../PageDescription';
import { media, colors, fonts } from '../../globalStyles';
import teamImg from '../../assets/img/team-img.png';
import { Link } from 'gatsby';

const StyledDescriptionBlock = styled.div`
	${P} {
		padding: 50px 0;
		color: ${colors.greyItalicTitle};
    font-family: ${fonts.openSans}
    font-size: 15px;
    line-height: 26px;
    ${media.maxSm`padding-bottom: 0;`}
    a{
			text-decoration: none;
			color: ${colors.greyItalicTitle};
			font-family: ${fonts.openSansBold};
		}
	}
	.team-img {
		max-width: 235px;
		margin: 0 auto 50px;
	}
	${H4} {
		font-size: 22px;
		text-transform: uppercase;
		text-align: center;
		font-family: ${fonts.loveloBlack};
		color: ${colors.blackTitle};
		margin-bottom: 10px;
		span {
			color: ${colors.orange};
		}
	}
`;
const DescriptionBlock = () => (
	<PageDescription
		title="The PixelTeh Team"
		subtitle="Impacting the world with state-of-the-art programming"
		bgColor="white"
	>
		<StyledDescriptionBlock>
			<figure className="team-img">
				{/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
				<img alt="box image of pixelteh team members" src={teamImg} />
			</figure>
			<H4>
				<span>PIXELTEH </span>ACTIVITY
			</H4>
			<P>
				It all begins with a great idea . . . yours! But where do you go from
				there? At <b>PixelTeh</b>, we specialize in taking your vision and
				making it a reality. Whether you want a new website, a
				<Link to={'/technologies-crossplatform'}> customized mobile app </Link>,
				cryptocurrency programming services, or a virtual reality game, our
				highly specialized back-end, full- stack, and front-end developers can
				achieve your goals to perfection!
			</P>
		</StyledDescriptionBlock>
	</PageDescription>
);

export default DescriptionBlock;
