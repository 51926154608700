import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
// Styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//----------------------------------------------------------------------------------------------------------------------

const sliderSettings = {
	slidesToShow: 3,
	infinite: true,
	autoplay: true,
};

export const CarouselWrapper = styled.div`
	width: 100%;
	//.slick-arrow {
	//	border-top: 4px solid #646464;
	//	border-right: 4px solid #646464;
	//	width: 15px;
	//	height: 15px;
	//
	//}
	.slick-arrow {
		display: inline-block !important;
		padding: 12px;
		margin-top: -22px;
		width: auto;
		height: auto;
		&:before {
			content: '';
			border-top: 4px solid #646464;
			border-right: 4px solid #646464;
			width: 15px;
			height: 15px;
			display: inline-block;
		}
	}
	.slick-prev {
		left: 10px;
		transform: rotate(-135deg);
		z-index: 1;
	}
	.slick-next {
		right: 10px;
		transform: rotate(45deg);
	}
	.slick-slider {
		outline: none !important;
	}
`;

const Carousel = ({ slides, settings }) => (
	<CarouselWrapper>
		<Slider {...sliderSettings} {...settings}>
			{slides}
		</Slider>
	</CarouselWrapper>
);

Carousel.propTypes = {
	slides: PropTypes.array,
	settings: PropTypes.object,
};

export default Carousel;
