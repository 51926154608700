// import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
// Components
import H4 from '../../_common/H4';
import P from '../../_common/P';

//----------------------------------------------------------------------------------------------------------------------

export const SlideTextWrapper = styled.div`
	position: absolute;
	left: 40px;
	bottom: 75px;

	padding: 20px;
	max-width: 435px;

	background: rgba(208, 222, 234, 0.9);
	transition: 0.5s;
	z-index: 1;

	&.show {
		opacity: 1;
	}
	&.hide {
		opacity: 0;
	}

	${H4} {
		color: #134b94;
		margin-bottom: 10px;
	}
	${P} {
		color: #82888d;
		line-height: 1.5;
	}
`;

const SlideText = () => {
	return null;
	// return (
	// 	<SlideTextWrapper className={show ? 'show' : 'hide'}>
	// 		<H4>{title}</H4>
	// 		<P>{description}</P>
	// 	</SlideTextWrapper>
	// );
};

// SlideText.propTypes = {
// 	description: PropTypes.string,
// 	show: PropTypes.bool,
// 	title: PropTypes.string,
// };

export default SlideText;
