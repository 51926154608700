import React from 'react';
import styled from 'styled-components';

import ContentSection from '../../ContentSection';
import Particles from '../../Particles';
import CentralButton from '../../_common/CenterButton';
import ContentSectionTopBorder from '../../_common/ContentSectionTopBorder';
import { colors } from '../../../globalStyles';

const StyledHireUs = styled.div`
	section {
		padding-top: 40px;
		padding-bottom: 70px;
	}
	h1 {
		color: ${colors.white} !important;
	}
	h2 {
		color: #889ebb !important;
	}
	h1,
	h2,
	a {
		position: relative;
		z-index: 90;
	}
`;

const HireUs = () => (
	<StyledHireUs>
		<ContentSectionTopBorder />
		<ContentSection
			title="Let’s Get to Work for You!"
			subtitle="<b>PixelTeh</b> is a hardworking and supportive team that brings your project from a fledgling idea to a tremendous success."
			fluid
			disablePadding
			bgColor="blueDark"
			paddingBottom={0}
			paddingBottomSm={0}
			noTopBorder
		>
			<CentralButton linkTo="/contact#contact-section">
				Let us get started
			</CentralButton>
			<Particles />
		</ContentSection>
	</StyledHireUs>
);

export default HireUs;
