import React from 'react';
import styled from 'styled-components';
import DevList from './DevList';
import PageDescription from '../../PageDescription';
import P from '../../_common/P';

const StyledDevelopers = styled.section``;

const Developers = () => (
	<PageDescription
		title="Meet Our Software Developers"
		subtitle="Where originality and expertise come together"
		bgColor="white"
	>
		<StyledDevelopers>
			<P>
				We structure everything at <b>PixelTeh</b> in a way to guarantee the
				development process is effective, smooth, and robust. Due to our large
				team of website developers, we can work under extremely tight deadlines
				to accomplish your goals – no matter how grand they may be.
			</P>
			<DevList />
		</StyledDevelopers>
	</PageDescription>
);

export default Developers;
