export default {
	study: {
		title: 'Ongoing Training & Conferences',
		text: `At <b>PixelTeh</b>, we actively strive to remain on the leading edge of innovations in the web/mobile, AI, AR, and VR industries. 
					Throughout the year, we take our staff to various conferences and training events to help them learn new things, grow, 
					and perform superior work for our clients. For instance, in 2017, we attended the React Amsterdam conference where 
					our team participated in discussions about the latest technologies being used in our field.<br><br>`,
		slides: {
			0: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 1',
				alt: 'main web summit image',
			},
			1: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 5',
				alt: 'web summit lobby displays',
			},
			2: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 6',
				alt: 'web summit lobby',
			},
			3: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 2',
				alt: 'web summit lobby with big screen',
			},
			4: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 3',
				alt: 'react Amsterdam lobby',
			},
		},
	},
	leisure: {
		title: 'Rewarding Our Team for Exceptional Work',
		text: `Our employees like working at <b>PixelTeh</b> because it is an engaging, enjoyable, and professional environment. 
					We care about things like a pleasant office environment, English language courses, corporate parties, 
					and other activities that unite the team. On top of this, we have some of our developers attend regular conferences 
					to learn the latest technologies in the field from prominent speakers and representatives from companies, 
					such as Facebook and others. All tie together to create a team that is skilled, informed, creative, 
					and dedicated to the successful completion of every project.`,
		slides: {
			0: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 1',
				alt: 'pixelteam playing football',
			},
			1: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 5',
				alt: 'pixelteam posing for a picture',
			},
			2: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 6',
				alt: 'pixelteam posing on tennis court',
			},
			3: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 7',
				alt: 'pixelteam grilling outside',
			},
			4: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 2',
				alt: 'pixelteh team posing with paintball guns',
			},
			5: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 3',
				alt: 'pixelteh team walking with paintball guns',
			},
			6: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 4',
				alt: 'pixelteh team gearing up for paintball game',
			},
			7: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 4',
				alt: 'team member hiding with paintball gun',
			},
			8: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 4',
				alt: 'team members playing volleyball',
			},
		},
	},
	work: {
		title: 'A Team Mentality Ensures Impressive Results',
		text: `Everything we do is intentional and laser-focused. Our team is divided into several major departments with a team lead 
					at the head. No matter what your project entails, we have the manpower, expertise, and resources needed. From start to finish, 
					we monitor every detail and regularly provide updates for our clients. In addition, the tech stack for our web projects is excellent. 
					We cover all principle programming languages and modern frameworks for both UI and back-end development. 
					A project manager (PM) is the primary point of contact for discussing priorities, project milestones, 
					resources, and everything else related to managing the project.`,
		slides: {
			0: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 1',
				alt: 'computer screen with code',
			},
			1: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 5',
				alt: 'team members looking at whiteboard',
			},
			2: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 6',
				alt: 'team members sitting around table',
			},
			3: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 7',
				alt: 'programmer working at computer',
			},
			4: {
				image: null,
				description:
					'Andouille venison drumstick, pork loin spare ribs biltong tongue pig chuck buffalo. Shank cupim frankfurter tongue flank shoulder capicola t-bone salami pork chop ham hock tail burgdoggen swine doner.',
				title: 'Slide 2',
				alt: 'team members surrounding a table',
			},
		},
	},
};
