import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { colors, fonts, media } from '../../../globalStyles';
// Components
import Carousel, { CarouselWrapper } from '../../Carousel';
import H5 from '../../_common/H5';
import P from '../../_common/P';

//----------------------------------------------------------------------------------------------------------------------

const StyledDevList = styled.div`
	.dev-item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.pointer {
			${media.laptop`display: none;`}
		}
		.dev-info {
			${H5} {
				color: ${colors.blackTitle};
				font-family: ${fonts.openSans};
				font-size: 70px;
				display: flex;
				align-items: center;
				flex-direction: row;
				position: relative;
				${media.laptop`${css`
					text-align: center;
					display: block;
					font-size: 50px;
					margin-top: 100px;
				`}`}
				${media.maxXs`
					font-size: 42px;
				`}
				span {
					height: 1px;
					background: ${colors.grey};
					flex-grow: 1;
					content: '';
					flex-direction: row-reverse;
					position: relative;
					top: 7px;
					left: 33px;
					&::before {
						position: absolute;
						left: 0;
						display: block;
						content: '';
						width: 8px;
						height: 8px;
						background-color: ${colors.orange};
						box-shadow: 0 0 9px rgba(0, 0, 0, 0.55);
						border-radius: 50%;
						top: -3px;
					}
				}
			}
			${P} {
				color: ${colors.greyItalicTitle};
				font-family: ${fonts.robotoLightItalic};
				font-size: 17px;
				line-height: 30px;
				padding: 50px 0 100px;
				text-align: left;
				${media.laptop`text-align: center;`}
			}
		}
		.dev-pictures {
			display: flex;
			&:not(.carousel-only) {
				${CarouselWrapper} {
					display: none;
				}
				.picture-wrapper {
					display: block;
				}
				${media.laptop`
						${CarouselWrapper}{
							display: block;
						}
						.picture-wrapper{
							display: none;
						}
				`}
			}
			.dev-name {
				display: block;
				font-size: 14px;
				color: #324157;
				text-align: center;
				padding: 30px 0 40px 0;
			}
			.picture-wrapper .picture-container {
				background: ${colors.white};
				max-width: 170px;
				padding: 10px;
				border: 1px solid #dadada;
				margin: 0 25px;
			}
			.picture-wrapper {
				img {
					width: 100%;
				}
			}
			.picture-slide .picture-container {
				margin: 0 auto;
				background: ${colors.white};
				max-width: 170px;
				padding: 10px;
				border: 1px solid #dadada;
				//margin: 0 25px;
				text-align: center;
			}
			.picture-slide {
				img {
					width: 100%;
				}
			}
		}
		&.leaderships {
			.dev-info {
				width: 66%;
				${media.maxXl`width: 57%;`}
			}
			.dev-pictures {
				${media.laptop`${css`
					justify-content: center;
					.picture-wrapper:first-child {
						margin-right: 60px;
					}
				`}`}
			}
		}
		&.architects {
			flex-direction: row-reverse;
			.dev-info {
				width: 51%;
				${H5} {
					flex-direction: row-reverse;
					span {
						left: -33px;
						&::before {
							left: 100%;
						}
					}
				}
			}
		}
		&.ai-engineers {
			align-items: center;
			.dev-info {
				width: 62%;
				${media.maxXl`width: 50%;`}
			}
			.dev-pictures {
				flex-wrap: wrap;
				width: 37%;
				flex-shrink: 0;
			}
		}
		&.vr-experts {
			flex-direction: row-reverse;
			align-items: center;
			.dev-info {
				width: 53%;
				${media.maxXl`width: 46%;`}
				${H5} {
					flex-direction: row-reverse;
					span {
						left: -33px;
						&::before {
							left: 100%;
						}
					}
				}
			}
			.dev-pictures {
				flex-wrap: wrap;
				width: 43%;
				flex-shrink: 0;
				justify-content: center;
				${media.maxXl`width: 50%;`}
				.picture-wrapper {
					margin-bottom: 20px;
				}
			}
		}
		&.qa-engineers {
			.dev-info {
				width: 40%;
			}
			.dev-pictures {
				flex-wrap: wrap;
				width: 54%;
				flex-shrink: 0;
				justify-content: center;
			}
		}
	}
	.dev-list-horizontal {
		.dev-item {
			display: block;
			padding-bottom: 80px;
			.dev-info {
				width: 100%;
				${H5} {
					text-align: center;
					flex-direction: column;
				}
				${P} {
					text-align: center;
					padding-bottom: 20px;
				}
			}
			.line-divider {
				width: 1px;
				height: 125px;
				background: ${colors.grey};
				margin: 0 auto;
				position: relative;
				margin-bottom: 30px;
				&::before {
					position: absolute;
					left: -4px;
					display: block;
					content: '';
					width: 8px;
					height: 8px;
					background-color: ${colors.orange};
					box-shadow: 0 0 9px rgba(0, 0, 0, 0.55);
					border-radius: 50%;
					top: -3px;
				}
				${media.laptop`${css`
					background: transparent;
					&::before {
						display: none;
					}
				`}`}
			}
			.dev-pictures {
				justify-content: center;
			}
			&.full-stack-developers {
				flex-direction: row-reverse;
				.dev-pictures {
					width: 100%;
				}
			}
		}
	}
	&&& .dev-item {
		${media.laptop`flex-direction: column;`}
	}
	.dev-item.mob-programmers {
		${media.laptop`padding-bottom: 0;`}
	}
	&&& .dev-info {
		${media.laptop`width: 100%;`}
	}
	&&& .dev-pictures {
		${media.laptop`width: 100%;`}
	}
`;

const ImageList = ({ list, devKey }) =>
	list.map(member => (
		<div className="picture-wrapper" key={member.name}>
			<figure>
				<div className="picture-container">
					<Img
						fixed={member.childImageSharp.fixed}
						alt={`${member.name} ${devKey}`}
					/>
				</div>
				<figcaption className="dev-name">{member.name}</figcaption>
			</figure>
		</div>
	));

ImageList.propTypes = {
	list: PropTypes.array,
	devKey: PropTypes.string,
};

const mapSlides = (list, devKey) =>
	list.map(member => (
		<div className="picture-slide" key={member.name}>
			<figure>
				<div className="picture-container">
					<Img
						fixed={member.childImageSharp.fixed}
						alt={`${member.name} ${devKey}`}
					/>
				</div>
				<figcaption className="dev-name">{member.name}</figcaption>
			</figure>
		</div>
	));

const settings = maxSlides => {
	const getSlidesToShow = breakPointValue => ({
		settings: {
			slidesToShow: breakPointValue > maxSlides ? maxSlides : breakPointValue,
		},
	});

	return {
		slidesToShow: 5,
		responsive: [
			{
				breakpoint: 479,
				...getSlidesToShow(1),
			},
			{
				breakpoint: 767,
				...getSlidesToShow(2),
			},
			{
				breakpoint: 1026,
				...getSlidesToShow(3),
			},
			{
				breakpoint: 1282,
				...getSlidesToShow(4),
			},
		],
	};
};
const DevCarousel = ({ devList, devKey }) => (
	<Carousel
		settings={settings(devList.length)}
		slides={mapSlides(devList, devKey)}
	/>
);
DevCarousel.propTypes = {
	devList: PropTypes.array,
	devKey: PropTypes.string,
};
const DevList = ({ devs }) => (
	<StyledDevList>
		<div className="dev-item leaderships">
			<div className="dev-info">
				<H5>
					Leadership
					<span className={'pointer'} />
				</H5>
				<P>
					Since 2007, our company has helped clients throughout the world enjoy
					tremendous success with their businesses by providing programming for
					artificial intelligence, augmented/virtual reality, mobile/web
					development, cryptocurrency, and much more. Our leaders have taken
					<b> PixelTeh</b> from a two-man operation to a company with more than
					50 in-house developers and staff. Located in Ukraine, our company
					provides programming and developer services for clients throughout the
					world, including the United States, Canada, Australia, the United
					Kingdom, Japan, and many others.
				</P>
			</div>
			<div className="dev-pictures leaderships">
				<DevCarousel devList={devs.le} devKey={'co-founder'} />
				<ImageList list={devs.le} />
			</div>
		</div>
		<div className="dev-item architects">
			<div className="dev-info">
				<H5>
					Solution Architects
					<span className={'pointer'} />
				</H5>
				<P>
					It takes a brilliant mind to come up with a smart solution.
					Fortunately, our solution architects have the experience, knowledge,
					and vision to understand the end goal and then make sure all the
					moving parts work together to reach it. We understand how the data
					looks on the back end and how it should appear on the front end, as
					well as the importance of UI and UX. Our project managers/solution
					architects have years of experience in the industry. They have managed
					full-scale projects for clients, such as Weather.com, CNN, Harley
					Davidson, and other high-profile clients.
				</P>
			</div>
			<div className="dev-pictures">
				<DevCarousel devList={devs.sa} devKey={'solution architect'} />
				<ImageList list={devs.sa} />
			</div>
		</div>
		<div className="dev-item ai-engineers">
			<div className="dev-info">
				<H5>
					AI/ML Engineers
					<span className={'pointer'} />
				</H5>
				<P>
					One of the hottest fields in the programming field is artificial
					intelligence. It’s not simply a trend, but a growing field that is not
					going away anytime soon. In fact, AI and machine learning are taking
					over everything – from Facebook, Google, and Amazon to even small
					mobile apps. Our AI and ML engineers have the ability to make your
					machines and programs “smart” enough to imitate the human mind and
					behaviors. We create apps and programs that can be used to identify
					credit card fraud detection, enable self-driving cars, and provide
					face detection and recognition.
				</P>
			</div>
			<div className="dev-pictures">
				<DevCarousel devList={devs.ml} devKey={'AI/ML engineer'} />
				<ImageList list={devs.ml} />
			</div>
		</div>
		<div className="dev-item vr-experts">
			<div className="dev-info">
				<H5>
					VR/AR Experts
					<span className={'pointer'} />
				</H5>
				<P>
					It’s reality, only better! Few emerging technologies have interested
					consumers like virtual reality and augmented reality. App users love
					it, gamers adore it, and businesses are clambering to use it to
					attract more customers! Allow our AR and VR experts to help you get on
					board with these innovative technologies. We have built AR/VR apps for
					retail stores, gaming companies, and other clients who want a unique
					way to capture the attention of their potential patrons. Anything is
					possible with <b>PixelTeh</b>. All you need is an idea, and we’ll work
					with you to develop a plan that fully meets your needs.
				</P>
			</div>
			<div className="dev-pictures">
				<DevCarousel devList={devs.vr} devKey={'VR/AR expert'} />
				<ImageList list={devs.vr} />
			</div>
		</div>
		<div className="dev-item qa-engineers">
			<div className="dev-info">
				<H5>
					QA Engineers
					<span className={'pointer'} />
				</H5>
				<P>
					Quality assurance is of the utmost importance to us. Without quality,
					we have nothing. After the coding is completed, our quality assurance
					engineers test everything to find any problems with the software
					before launching the project. We identify and analyze any bugs found
					during testing. Then, we fix them. This process continues until we
					have a flawless product. But our QA engineers don’t simply show up at
					the end. Instead, they monitor every phase of the development process,
					making sure the software adheres to our strict standards and your
					specifications.
				</P>
			</div>
			<div className="dev-pictures">
				<DevCarousel devList={devs.qa} devKey={'QA engineer'} />
				<ImageList list={devs.qa} />
			</div>
		</div>
		<div className="dev-list-horizontal">
			<div className="dev-item mob-programmers">
				<div className="dev-info">
					<H5>Mobile Application Programmers</H5>
					<P>
						Are you stuck on the web? Some companies are still operating using
						technology from the early 2000s or even last century. Even though
						they may have a fully optimized and feature-packed website, they
						need more than that to compete in today’s competitive, high-tech
						world. Take your business into the 21 st century with a mobile app!
						Our programmers expand your reach by creating mobile apps that
						generate interest, entertain, and inform your target market. And
						now, with artificial intelligence, virtual reality, and augmented
						reality, your options are exciting and endless.
					</P>
				</div>
				<div className="line-divider" />
				<div className="dev-pictures">
					<DevCarousel devList={devs.mo} devKey={'mobile developer'} />
					<ImageList list={devs.mo} />
				</div>
			</div>
			<div className="dev-item full-stack-developers">
				<div className="dev-info">
					<H5>Full Stack Developers</H5>
					<P>
						Unlike less experienced software development companies, we do it
						all. Back end, front end, QA, and more! We are full-stack developers
						that manage your project from conception to completion . . . .and
						beyond. In addition, our specialties are wide ranging. More than a
						decade ago, we started out as a web/mobile development company. But
						now, we have a team of programmers who specialize in every area –
						AI, VR, ML, web/mobile app, cryptocurrency, and anything else you
						can think of. Let us know what your project is, and we’ll make it a
						success.
					</P>
				</div>
				<div className="line-divider" />
				<div className="dev-pictures carousel-only">
					<DevCarousel devList={devs.fs} devKey={'full stack developer'} />
				</div>
			</div>
		</div>
	</StyledDevList>
);

DevList.propTypes = {
	devs: PropTypes.object,
};

function withDevList(Component) {
	return function WithDevList(props) {
		const data = useStaticQuery(graphql`
			query {
				allFile(filter: { relativeDirectory: { eq: "team" } }) {
					edges {
						node {
							name
							childImageSharp {
								# Specify the image processing specifications right in the query.
								# Makes it trivial to update as your page's design changes.
								fixed(width: 148, height: 222, quality: 100) {
									...GatsbyImageSharpFixed_withWebp
								}
							}
						}
					}
				}
			}
		`);
		const devs = {};
		data.allFile.edges.forEach(({ node }) => {
			const [name, category] = node.name.split('-');
			if (~['fs', 'le', 'sa', 'qa', 'ml', 'vr', 'mo'].indexOf(category)) {
				const newVal = { ...node, name: transformDevName(name) };
				devs[category] = devs[category]
					? devs[category].concat(newVal)
					: [].concat(newVal);
			}
		});
		return <Component {...props} devs={devs} />;
	};
}

function transformDevName(devName) {
	return devName
		.split('_')
		.map(capitalizeFirstLetter)
		.join(' ');
}

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

// const ConnectedDevList = () => {
// 	const data = useStaticQuery(graphql`
//
// 	`);
//
// 	return <DevList list={[]}/>
// };
export default withDevList(DevList);
