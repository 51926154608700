import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { media } from '../../../globalStyles';
// Components
import SlideText from './SlideText';
// Hooks
import useInterval from '../../../hooks/use-interval';
// import CoverImg from '../../_common/CoverImg';

//----------------------------------------------------------------------------------------------------------------------

export const SliderWrapper = styled.div`
	.slick-slide {
		position: absolute;
		left: 0;
		height: 566px;
		width: 20% !important;
	}
	.slick-slide.slick-active {
		width: 60% !important;
	}
	.slick-slide img {
		height: 100%;
		width: 100%;
	}
	.gatsby-image-wrapper {
		position: absolute !important;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
	}
`;

const Track = styled.div`
	position: relative;
	height: 566px;
	overflow: hidden;
`;

const Slide = styled.div`
	position: absolute;
	height: 566px;
	width: 70%;
	left: ${({ index }) => `${70 + (index - 1) * 15}%;`}
	border-left: 1px solid #ffffff;
	transition: all 0.3s;
	&.active {
		left: 0;
		border-left: 0;
		${media.maxSm`top: 0;`};
	}
	& img {
		height: 566px;
	}
`;

const LastActiveSlide = styled.div`
	position: absolute;
	height: 566px;
	left: 0;
	z-index: 0;
	width: 70%;
	& img {
		height: 566px;
	}
`;

const sliderInterval = 5000;

const Slider = ({ slidesData }) => {
	const [slides, setSlides] = useState(slidesData);
	const [lastSlide, setLastSlide] = useState(slidesData[0]);
	const [showText, setShowText] = useState(true);
	const [isRunning] = useState(false);
	// const [isRunning, setIsRunning] = useState(false);

	useInterval(
		() => {
			reorderSlides(slides[1].id);
		},
		isRunning ? sliderInterval : null
	);

	useEffect(
		() => {
			setSlides(slidesData);
		},
		[slidesData]
	);

	const reorderSlides = id => {
		const newSlides = [...slides];
		const index = newSlides.findIndex(item => item.id === id);

		setLastSlide({ ...slides[0] });
		setSlides([...newSlides.splice(index), ...newSlides]);

		setShowText(false);
		setTimeout(() => setShowText(true), 500);
	};
	return (
		<SliderWrapper>
			<Track
			// onMouseEnter={() => setIsRunning(false)}
			// onMouseLeave={() => setIsRunning(true)}
			>
				<LastActiveSlide>
					<Img fluid={lastSlide.image.fluid} />
				</LastActiveSlide>

				<SlideText
					show={showText}
					title={showText ? slides[0].title : lastSlide.title}
					description={showText ? slides[0].description : lastSlide.description}
				/>

				{slides.map((slide, index) => (
					<Slide
						key={slide.id}
						index={index}
						className={index === 0 && 'active'}
						onClick={() => reorderSlides(slide.id)}
					>
						<Img fluid={slide.image.fluid} />
					</Slide>
				))}
			</Track>
		</SliderWrapper>
	);
};

Slider.propTypes = {
	slidesData: PropTypes.array,
};

export default Slider;
