import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
// import Img from 'gatsby-image';
import { colors, fonts, fontSizes, media } from '../../../globalStyles';
// Components
import Carousel, { CarouselWrapper } from '../../Carousel';
import ContectSectionTopBorder from '../../_common/ContentSectionTopBorder';
import H3 from '../../_common/H3';
import H4 from '../../_common/H4';
import P from '../../_common/P';
import Slider, { SliderWrapper } from './Slider';
import SlideText, { SlideTextWrapper } from './SlideText';

import tabsData from './tabsData';
import CoverImg from '../../_common/CoverImg';

//----------------------------------------------------------------------------------------------------------------------

const StyledTeamTabs = styled.section`
	.nav-tabs {
		display: flex;
		justify-content: center;
		padding: 30px 0;
		${media.maxXs`
			flex-direction: column;
			align-items: center;
		`}
		li {
			padding: 0 40px;
			${media.maxSm`padding: 0 25px;`}
			${media.maxXs`margin-bottom: 10px;`}
			a {
				padding: 10px 15px;
				border: 1px solid transparent;
				border-radius: 4px 4px 0 0;
				color: ${colors.greyItalicTitle};
				font-family: ${fonts.loveloBlack};
				font-size: 22px;
				line-height: 1.42857143;
				text-transform: uppercase;
				text-decoration: none;
			}
			&.active a {
				color: ${colors.orange};
				text-decoration: underline;
			}
			&:not(.active):hover a {
				border-color: #eee #eee #ddd;
				background-color: #eee;
			}
		}
	}
	.tab-pane {
		${H3} {
			color: ${colors.blackTitle};
			font-family: ${fonts.loveloBlack};
			font-size: 28px;
			text-align: center;
			padding-top: 50px;
			padding-bottom: 50px;
		}
		${P} {
			color: ${colors.greyItalicTitle};
			font-family: ${fonts.openSans};
			font-size: 15px;
			line-height: 26px;
			text-align: center;
			@media (max-width: 1199px) {
				min-height: 130px;
			}
			@media (max-width: 799px) {
				min-height: 182px;
			}
			@media (max-width: 707px) {
				min-height: 208px;
			}
			@media (max-width: 628px) {
				min-height: 234px;
			}
			@media (max-width: 554px) {
				min-height: 260px;
			}
			@media (max-width: 506px) {
				min-height: 286px;
			}
		}
	}
	.images-block {
		margin: 0 -15px;
		${SliderWrapper} {
			margin-top: 100px;
		}
		${CarouselWrapper} {
			display: none;
			margin-top: 60px;
		}
		.slick-dots {
			position: absolute;
			bottom: 20px;
			list-style: none;
			z-index: 1;
		}
		.slick-dots li {
			width: 13px;
			height: 13px;
			border: 2px solid white;
			border-radius: 50%;
		}
		.slick-dots li.slick-active {
			background: #ffffff;
		}
		.slick-dots li button::before {
			display: none;
		}
		${media.laptopS`
			${SliderWrapper}{
				display: none;
			}
			${CarouselWrapper} {
				display: block;
			}
		`}
	}
	${media.maxXs`
		display: none;
	`}
`;

const CarouselSlide = styled.div`
	position: relative;
	height: 540px;
	${SlideTextWrapper} {
		//bottom: 20px;
		max-width: 300px;
		${H4} {
			${`font-size: ${fontSizes.h5};`}
		}
		${P} {
			line-height: 1.2;
			${`font-size: ${fontSizes.tiny};`}
		}
	}
`;

const mapSlides = list => {
	return list.map((slide, index) => (
		<CarouselSlide key={index}>
			<CoverImg fluid={slide.image.fluid} title={slide.alt} />
			<SlideText
				description={slide.description}
				show={true}
				title={slide.title}
			/>
		</CarouselSlide>
	));
};
const sliderSettings = {
	arrows: false,
	dots: true,
	infinite: true,
	autoplay: true,
	slidesToShow: 1,
};
const tabs = ['study', 'leisure', 'work'];
const TeamTabs = ({ data }) => {
	// const { tabs, tabsData, activeTab } = this.state;
	const [activeTab, setActiveTab] = useState(tabs[0]);
	const handleClick = tab => e => {
		e.preventDefault();
		setActiveTab(tab);
	};
	return (
		<StyledTeamTabs>
			<ContectSectionTopBorder />
			<ul className="nav-tabs">
				{tabs.map((tab, index) => (
					<li className={tab === activeTab ? 'active' : ''} key={index}>
						<a href="/" onClick={handleClick(tab)}>
							{tab}
						</a>
					</li>
				))}
			</ul>
			<ContectSectionTopBorder />
			<div className="tab-pane">
				<div className="container-fluid">
					<H3>{data[activeTab].title}</H3>
					<P dangerouslySetInnerHTML={{ __html: data[activeTab].text }} />
					<div className="images-block">
						<Carousel
							slides={mapSlides(data[activeTab].slides)}
							settings={sliderSettings}
						/>
						<Slider slidesData={data[activeTab].slides} />
					</div>
				</div>
			</div>
		</StyledTeamTabs>
	);
};

TeamTabs.propTypes = {
	data: PropTypes.object,
};

function withTeamTabsImages(Component) {
	return function WithTabsImages(props) {
		const data = useStaticQuery(graphql`
			query {
				allFile(
					filter: { relativeDirectory: { in: ["study", "leisure", "work"] } }
				) {
					edges {
						node {
							name
							childImageSharp {
								# Specify the image processing specifications right in the query.
								# Makes it trivial to update as your page's design changes.
								fluid(maxWidth: 1450, maxHeight: 700, quality: 100) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		`);
		data.allFile.edges.forEach(({ node: { name, childImageSharp } }) => {
			const [category, index] = name.split('-');
			tabsData[category].slides[index].image = childImageSharp;
			tabsData[category].slides[index].id = index;
		});
		Object.keys(tabsData).forEach(tab => {
			tabsData[tab].slides = Object.keys(tabsData[tab].slides).map(
				slide => tabsData[tab].slides[slide]
			);
		});
		return <Component {...props} data={tabsData} />;
	};
}

// withTeamTabsImages.displayName = `withTeamTabsImages(${Component.displayName || Component.name}`;
// withTeamTabsImages.displayName = 'TeamTabs';

export default withTeamTabsImages(TeamTabs);
