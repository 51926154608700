import { graphql, useStaticQuery } from 'gatsby';

export const useTeamPicture = () => {
	// const image = data.file.childImageSharp.fluid
	const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "our-team-bg2.jpg" }) {
				...ChildImageSharpFluid
			}
		}
	`);
	return data.file.childImageSharp.fluid;
};
