import React from 'react';
import PropTypes from 'prop-types';

import PageLayout from '../layouts/PageLayout';
import SubpageTopCover from '../components/SubpageTopCover';
import DescriptionBlock from '../components/TeamPage/DescriptionBlock';
import TeamTabs from '../components/TeamPage/TeamTabs';
import Developers from '../components/TeamPage/Developers';
import HireUs from '../components/TeamPage/HireUs';
import { useTeamPicture } from '../hooks/use-team-picture';
import { team } from '../seoConfig';

const TeamPage = ({ location }) => {
	const img = useTeamPicture();
	return (
		<PageLayout location={location} seoConfig={team}>
			<SubpageTopCover
				backgroundImg={img}
				altText="pixelteh team at conference table"
			>
				Innovation Starts with <b>PixelTeh</b>
			</SubpageTopCover>
			<DescriptionBlock />
			<TeamTabs />
			<Developers />
			<HireUs />
		</PageLayout>
	);
};

TeamPage.propTypes = {
	location: PropTypes.object,
};

export default TeamPage;
